import React from "react";
import * as styles from "./pagination.module.scss";
import { Link } from "gatsby";

const Pagination = (props) => {
  return (
    <React.Fragment>
      <div className={styles.pagination}>
        {props.prePage !== "" && (
          <div className={styles.prev + " " + styles.button}>
            <Link to={props.prePage}>Prev</Link>
          </div>
        )}
        {props.nextPage !== "" && (
          <div className={styles.next + " " + styles.button}>
            <Link to={props.nextPage}>Next</Link>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Pagination;
